export const ENVIRONMENT = {
  dev: {
    ENDPOINT: "https://api-dev.zenvoya.ai",
    // ENDPOINT: "https://api-dev-main.zenvoya.ai",
    recommendedTripsURL: "https://app-dev.zenvoya.ai",
  },
  test: {
    ENDPOINT: "https://api-test.zenvoya.ai",
    recommendedTripsURL: "https://app-prompt.zenvoya.ai",
  },
  staging: {
    ENDPOINT: "https://api-staging.zenvoya.ai",
    recommendedTripsURL: "https://app-staging.zenvoya.ai",
  },
  native: {
    ENDPOINT: "https://api-native.zenvoya.ai",
    recommendedTripsURL: "https://app-native.zenvoya.ai",
  },
};

const { ENDPOINT, recommendedTripsURL } = ENVIRONMENT.test;

export { ENDPOINT, recommendedTripsURL };
